<template>
  <div>
    <!-- Top Footer -->
    <!-- <footer id="top-footer" v-if="topfooter && isShowTopFooter">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <router-link
              class="card-with-link"
              to="/info/shipping-delivery-service"
              target="_blank"
            >
              <div class="card">
                <div class="card-body">
                  <p class="card-text card-text-title">Delivery Policy</p>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-6 col-md-6">
            <router-link
              class="card-with-link"
              to="/info/return-refund-policy"
              target="_blank"
            >
              <div class="card">
                <div class="card-body">
                  <p class="card-text card-text-title">Refund Policy</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </footer> -->

    <!-- Bottom Footer -->
    <!-- <footer v-if="isShowBottomFooter" id="bottom-footer" class="container py-5">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12">
          <h5>Information</h5>
          <ul class="list-unstyled text-small">
            <li @click="scrollTop()">
              <router-link to="/info/about-us" class="text-muted"
                >About Us</router-link
              >
            </li>
            <li @click="scrollTop()">
              <router-link class="text-muted" to="/info/privacy-notice"
                >Privacy Notice</router-link
              >
            </li>
            <li @click="scrollTop()">
              <router-link
                class="text-muted"
                to="/info/shipping-delivery-service"
                >Shipping & Delivery Service</router-link
              >
            </li>
            <li @click="scrollTop()">
              <router-link class="text-muted" to="/info/return-refund-policy"
                >Return & Refund Policy</router-link
              >
            </li>
            <li @click="scrollTop()">
              <router-link class="text-muted" to="/store/home-nursing/apply"
                >Become a Selcare Home Nurse</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <h5>Account</h5>
          <ul class="list-unstyled text-small">
            <li @click="scrollTop()">
              <router-link to="/customer/dashboard" class="text-muted"
                >My Account</router-link
              >
            </li>
            <li @click="scrollTop()">
              <router-link to="/customer/dashboard" class="text-muted"
                >Order History</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <h5>Customer Service</h5>
          <div class="row">
            <div class="col-6 col-md">
              <ul class="list-unstyled text-small">
                <li>
                  <span class="text-muted font-weight-bold"
                    >Customer Care:</span
                  >
                </li>
                <li>
                  <p style="line-height: 1.5" class="text-muted">
                    <a class="text-muted" href="tel:0379319309"
                      ><i class="fa fa-phone"></i> 03-7931 9309</a
                    >
                    <br />
                    Operating Hour : 9.00am to 5.00pm (Monday - Friday, except
                    Public Holidays)
                  </p>
                </li>
                <li @click="scrollTop()" style="margin-top: -10px">
                  <router-link class="text-muted" to="/store/feedback"
                    >Feedback</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-6 col-md">
              <ul class="list-unstyled text-small">
                <li>
                  <span class="text-muted font-weight-bold">Head Office:</span>
                </li>
                <li>
                  <p style="line-height: 1.5" class="text-muted">
                    Selcare Pharmacy Sdn. Bhd. <br />
                    No 7A, Block X, Tingkat Bawah <br />
                    Universiti Selangor Campus (UNISEL), <br />
                    Shah Alam, Jalan Zikron D 7/D Seksyen 7, <br />
                    40000, Shah Alam, Selangor
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer> -->

    <!-- Copyright Footer -->
    <footer id="copyright-footer" v-if="isShowCopyRight">
      <div class="row mx-0">
        <!-- <div class="col-lg-12">
                    <ul class="social-nav">
                        <li><a href="#" target="_blank"><i class="fa fa-facebook"></i></a> FB </li>
                        <li><a href="#" target="_blank"><i class="fa fa-twitter"></i></a> TW </li>
                        <li><a href="#" target="_blank"><i class="fa fa-instagram"></i></a> IG </li>
                        <li><a href="#" target="_blank"><i class="fa fa-linkedin"></i></a> LI </li>
                    </ul>
                </div> -->
        <div class="col-lg-12">
          <p style="margin-bottom: 0">
            2021
            <router-link to="/store" class="text-muted" title="SelCare"
              >Selcare</router-link
            >. All Rights Reserved. <br />
            Selcare Pharmacy Sdn Bhd, Gallery 5 &amp; 6, Ground Floor, Menara
            PKNS, Jalan Yong Shook Lin, 46050 Petaling Jaya, Selangor Darul
            Ehsan.
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  props: ["topfooter"],
  data() {
    return {
      isShowBottomFooter: true,
      isShowTopFooter: true,
      isShowCopyRight: true,
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    if (this.$route.query.redirect && this.$route.query.redirect == "booking") {
      this.isShowBottomFooter = false;
    } else if (
      this.$route.meta &&
      this.$route.meta.title == "E-prescriptions"
    ) {
      this.isShowBottomFooter = false;
      this.isShowTopFooter = false;
      this.isShowCopyRight = false;
    } else if ( this.$route.meta &&
      this.$route.meta.title == "Policy") {
         this.isShowBottomFooter = false;
         this.isShowCopyRight = false;
         this.isShowTopFooter = false;
    } else if ( this.$route.meta &&
      this.$route.meta.title == "Login") {
         this.isShowBottomFooter = false;
         this.isShowCopyRight = false;
         this.isShowTopFooter = false;
    } else this.isShowBottomFooter = true;
  },
};
</script>

<style>
/** Top Footer */
footer#top-footer {
  padding: 85px 0 55px 0;
  color: #999;
  text-align: center;
  background-color: #f6f6f6;
  border-top: 0.05rem solid #e5e5e5;
}
footer#top-footer .card {
  border: none;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}
footer#top-footer .card .card-body {
  padding: 35px 40px;
}
footer#top-footer .card .card-text-title {
  font-weight: bold;
  letter-spacing: 0.2em;
  /*font-family: "Roboto Slab",serif;*/
  font-size: 24px;
  line-height: 38px;
  text-transform: uppercase;
  color: #000000;
}

/** Bottom Footer */
footer#bottom-footer h5 {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 18px;
  /*font-family: "Roboto Slab",serif;*/
  font-weight: bold;
  color: #000;
}
footer#bottom-footer a {
  text-decoration: unset;
}
footer#bottom-footer ul {
  margin-top: 20px;
}
footer#bottom-footer ul li {
  margin-bottom: 0.25em;
  line-height: 1.7;
  color: #666;
  text-transform: capitalize;
  /* font-family: "Karla",arial,sans-serif; */
}
@media (max-width: 768px) {
  footer#bottom-footer .row .col-sm-12 {
    margin-bottom: 30px;
  }
}

/** Copyright Footer */
footer#copyright-footer {
  padding: 2.5rem 0;
  color: #333;
  text-align: center;
  background-color: #f6f6f6;
  border-top: 0.05rem solid #e5e5e5;
  font-size: 14px;
  /* font-family: "Karla",arial,sans-serif; */
}
footer#copyright-footer ul.social-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
}
footer#copyright-footer ul.social-nav li {
  display: inline-block;
  padding: 0 22px;
  font-size: 14px;
}
footer#copyright-footer p {
  line-height: 1.9;
}

a.card-with-link:hover {
  text-decoration: unset;
}
</style>