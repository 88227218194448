<template>
    <div id="forgotpassword">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <div v-if="authSuccess && !isSetNewPass" class="alert alert-success text-center" role="alert">
                    <i class="fas fa-check-circle"></i><span> Your email has been successfully sent. </span>
                </div>
                <div v-if="authSuccess && isSetNewPass" class="alert alert-success text-center" role="alert">
                    <i class="fas fa-check-circle"></i><span> Your password has been reset successfully. Please go back to login.</span>
                </div>
                <div v-if="authError" class="alert alert-danger text-center" role="alert">
                    <i class="fas fa-times-circle"></i><span> Sorry. {{ authError }}</span>
                </div>
                <!-- Send Email -->
                <div class="card">
                    <div v-if="!isSetNewPass" class="card-body">
                        <h5 class="card-title text-center">Reset Password</h5>
                        
                        <div class="form-group">
                            <input class="form-control" type="email" placeholder="Email" v-model="email">
                        </div>
                        <button v-if="!authProcessing" class="btn btn-red" @click="submitResetMail">Send Email</button>
                        <button v-else class="btn btn-red" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>

                        <div class="other-info mt-2">
                            <span>
                                <router-link to="/auth/login">Go back</router-link>
                            </span>
                        </div>
                    </div>

                    <!-- Reset Password -->
                    <div v-else class="card-body">
                        <h5 class="card-title text-center">Set New Password</h5>
                        
                        <div class="form-group">
                            <input class="form-control" type="password" placeholder="Password" v-model="new_pass">
                        </div>
                        <div class="form-group">
                            <input class="form-control" type="password" placeholder="Confirm Password" v-model="new_pass_confirm">
                        </div>
                        <button v-if="!authProcessing" class="btn btn-red" @click="submitResetPassword">Reset Password</button>
                        <button v-else class="btn btn-red" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>

                        <div class="other-info mt-2">
                            <span>
                                <router-link to="/auth/login">Go back</router-link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data() {
        return {
            email: null,
            new_pass: null,
            new_pass_confirm: null,
            email_status: null,
            isSetNewPass: false

        }
    },
    computed: {
        ...mapGetters(['authProcessing', 'authError', 'authSuccess'])
    },
    methods: {
        ...mapActions(['forgotPassword', 'resetPassword']),
        submitResetMail(){
            this.forgotPassword({
                email: this.email
            })
        },
        submitResetPassword(){
            this.resetPassword({
                code: this.$route.query.code,
                password: this.new_pass,
                passwordConfirmation: this.new_pass_confirm,
            })
            //make empty field
            this.new_pass = ''
            this.new_pass_confirm = ''
        }
    },
    mounted(){
        if(this.$route.query.code){
            this.isSetNewPass = true
        }

        let queryParams = this.$route.query.email_send_status
        if(queryParams && queryParams == 'success') this.email_status = 'success'
        else if(queryParams && queryParams == 'failed') this.email_status = 'failed'

    }
}
</script>

<style scoped>
#forgotpassword {
    height: calc(100vh - 95px);
    padding: 60px 0px;
    /*font-family: "Karla",serif;*/
}

.img {
    padding: 130px 0px;
}

.card {
    border-radius: 0px;
    border: none;
}
.card .card-body {
    margin: 10px 0px;
}
.card .card-title {
    margin-bottom: 30px;
}
.card .card-body .form-group {
    margin-bottom: 25px;
}
.card .card-body input {
    border-radius: 0px;
}
.card .card-body button {
    width: 100%;
    border-radius: 0px;
    border: none;
}
.card .card-body .btn-red{
    background-color: #a91a18;
    color: #fff;
}
.card .card-body .btn-facebook{
    background-color: #1877f2;
    color: #fff;
    font-weight: bold;
}
.card .card-body .btn-google{
    background-color: #4285f4;
    color: #fff;
    font-weight: bold;
}
.card .card-body p {
    font-size: 14px;
}
.card .card-body span {
    font-size: 14px;
}
.card .card-body a {
    color: #a91a18;
}
</style>