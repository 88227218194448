<template>
  <div class="main-body">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6">
        <div class="p-4">
          <div :style="!authError ? 'height: 230px' : 'height: 280px'">
            <h4 class="card-title text-center ff-title fw-strong mb-4">Login</h4>
            <p class="text-danger ff-desc" v-if="authError">Sorry, {{authError}}</p>
            <div class="form-group">
              <input
                class="form-control custom-radius py-4"
                type="email"
                placeholder="Email"
                v-model="email"
                @keyup.enter="submitAuth"
              />
            </div>
            <div class="form-group">
              <input
                class="form-control custom-radius py-4"
                type="password"
                placeholder="Password"
                v-model="password"
                @keyup.enter="submitAuth"
              />
            </div>
            <div class="other-info mt-2 mb-3 text-right small">
              <span>
                <router-link to="/auth/resetpassword" class="text-dark"
                  >Forgot Password</router-link
                >
              </span>
            </div>
          </div>
          <button
            v-if="!authProcessing"
            class="btn btn-red w-100 custom-radius"
            style="padding-top: 12px; padding-bottom: 12px"
            @click="submitAuth"
          >
            Login
          </button>
          <button v-else class="btn btn-red w-100 custom-radius" @click="submitAuth" disabled style="padding-top: 12px; padding-bottom: 12px">
            <span class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            class="mt-4 btn btn-selangkah-login w-100 custom-radius"
            style="padding-top: 12px; padding-bottom: 12px"
            @click="openSelangkahSSO"
          >
            <img src="/img/selangkah-logo.png" height="25" alt="" class="float-left ml-2">
            <span>Login with Selangkah</span>
          </button>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 border-left">
        <div class="p-4">
          <div :style="!authError ? 'height: 230px' : 'height: 280px'">
            <h4 class="card-title text-center mb-4  ff-title fw-strong">New to Selcare?</h4>
            <p class="ff-desc text-muted">Register Now!</p>
            <p class="ff-desc text-muted">
              Create your account and enjoy exclusive online <br />
              benefits from us!
            </p>
          </div>
          <button
            type="button"
            class="btn btn-red w-100 text-white text-decoration-none custom-radius"
            style="padding-top: 12px; padding-bottom: 12px"
            @click="gotoRegis()"
          >
            Register
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex justify-content-center align-items-center">
      <div class="row mt-2">
        <div class="col-md-12">
          <div class="wrap text-center text-muted">Or</div>
          <button class="btn btn-facebook" :onclick="authViaFacebook()">
            Continue with Facebook
          </button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import API from "./../../utils/API";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      username: null,
      email: null,
      password: null,
    };
  },
  computed: {
    ...mapGetters(["authProcessing", "authError"]),
  },
  methods: {
    ...mapActions(["login", "clearAllErrors"]),
    submitAuth() {
      let redirectto = this.$route.query.redirect
        ? this.$route.query.redirect
        : null;

      this.login({
        email: this.email,
        password: this.password,
        redirectto: redirectto,
      });
    },
    gotoRegis() {
      this.$router.push({
        path: "/auth/signup",
      });
    },
    authViaFacebook() {
      let url = `${API.getAPIUrl()}/connect/facebook`;
      return `location.href='${API.getAPIUrl()}/connect/facebook'`;
    },
    openSelangkahSSO(){
      // let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      // width=900,height=800,left=-0,top=-0`;

      // window.open('https://app.selangkah.my/sso-selangkah/selangkah/sign_in_selangkah', 'selangkahsso', params)
      window.open('https://app.selangkah.my/sso-selangkah/selangkah/sign_in_selangkah', '_self')
    }
  },
  mounted() {
    if(this.$route.query.registered) {
      Swal.fire({
        icon: "success",
        html: "<p class='mb-0'>Registered successfully. Please check your email to confirm verification.</p>",
        customClass: {
          popup: "swal2-default",
        },
      });
      this.$router.replace({'query': null});
    }

    if(this.$route.query.confirm) {
      Swal.fire({
        icon: "success",
        html: "<p class='mb-0'>Your account has been verified successfully.</p>",
        customClass: {
          popup: "swal2-default",
        },
      });
      this.$router.replace({'query': null});
    }
  },
  beforeDestroy() {
    this.clearAllErrors()
  },
};
</script>

<style scoped>
.main-body {
  padding: 60px 0px;
  /*font-family: "Karla",serif;*/
}

.img {
  padding: 130px 0px;
}

.card {
  border-radius: 0px;
  border: none;
}
.card .card-body {
  margin: 10px 0px;
}
.card .card-title {
  margin-bottom: 30px;
}
.card .card-body .form-group {
  margin-bottom: 25px;
}
.card .card-body input {
  border-radius: 0px;
}
.card .card-body button {
  width: 100%;
  border-radius: 0px;
  border: none;
}
.card .card-body .btn-facebook {
  background-color: #1877f2;
  color: #fff;
  font-weight: bold;
}
.card .card-body .btn-google {
  background-color: #4285f4;
  color: #fff;
  font-weight: bold;
}
.card .card-body p {
  font-size: 14px;
}
.card .card-body span {
  font-size: 14px;
}
.card .card-body a {
  color: #a91a18;
}
/* .card .card-body .other-info {

} */

/** Centre line */
.wrap {
  margin: 0px 0px;
  position: relative;
  height: 50px;
}

p.centre-line {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
p.centre-line:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1.5px;
  top: 50%;
  left: 0;
  z-index: -1;
  background: #dbdbdb;
}
p.centre-line span {
  background-color: #ffffff;
  color: #dbdbdb;
  padding: 1rem;
  display: inline-block;
  text-transform: uppercase;
}
.btn.btn-red {
  background-color: #a91a18 !important;
  color: #fff !important;
}

.btn.btn-selangkah-login {
  border: 1px solid #383838;
}
.btn.btn-selangkah-login span {
  font-weight: 700;
}

.wrap {
  margin: 10px 0px;
  position: relative;
  height: 50px;
}

.btn-facebook {
  background-color: #1877f2;
  color: #fff;
  font-weight: bold;
}
</style>