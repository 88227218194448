<template>
  <div class="main-body">
    <div class="row">
      <!-- <div class="col-md-6">
                <div class="img text-center">
                    <img src="/img/logo_full.png" alt="" style="width:70%;">
                </div>
            </div> -->
      <!-- <div class="col-md-6"> -->
      <div class="col-lg-6 offset-lg-3">
        <!-- <div
          v-if="authError"
          class="alert alert-danger text-center"
          role="alert"
        >
          <i class="fas fa-times-circle"></i
          ><span> Sorry. {{ authError }}</span>
        </div> -->
        <div class="card bg-no custom-radius">
          <div class="card-body">
            <h4 class="card-title ff-title fw-strong">Sign Up</h4>
            <div class="mb-3">
              <p class="ff-desc text-danger mb-0" v-if="authError">
                Sorry, {{ authError }}
              </p>
              <p class="ff-desc text-danger mb-0" v-if="errorPass">
                Sorry, {{ errorPass }}
              </p>
              <p class="ff-desc text-danger mb-0" v-if="errorNRIC">
                Sorry, {{ errorNRIC }}
              </p>
              <p class="ff-desc text-danger mb-0" v-if="isErrorRequired">
                Sorry, please fill in all the required fields.
              </p>
            </div>
            <div class="form-group">
              <!-- <input class="form-control" type="text" placeholder="Username" v-model="username"> -->
              <input
                class="form-control custom-radius py-4"
                type="text"
                placeholder="Fullname"
                v-model="username"
                @keyup.enter="submitRegistration"
              />
            </div>
            <div class="form-group">
              <input
                class="form-control custom-radius py-4"
                type="email"
                placeholder="Email"
                v-model="email"
                @keyup.enter="submitRegistration"
              />
            </div>
            <div class="form-group">
              <input
                maxlength="12"
                type="text"
                class="form-control custom-radius py-4"
                :class="{
                  border: errorNRIC,
                  'border-danger': errorNRIC,
                }"
                :style="`border-width: ${errorNRIC ? '2px !important' : '1px'}`"
                placeholder="NRIC"
                v-model="nric"
                @keyup.enter="submitRegistration"
              />
            </div>
            <div class="form-group">
              <input
                class="form-control custom-radius py-4"
                type="password"
                placeholder="Password"
                v-model="password"
                @keyup.enter="submitRegistration"
              />
            </div>
            <div class="form-group">
              <input
                class="form-control custom-radius py-4"
                :class="{
                  border: errorPass,
                  'border-danger': errorPass,
                }"
                :style="`border-width: ${errorPass ? '2px !important' : '1px'}`"
                type="password"
                placeholder="Confirm Password"
                v-model="confirm_password"
                @keyup.enter="submitRegistration"
              />
            </div>
            <button
              v-if="!authProcessing"
              class="btn btn-red custom-radius"
              style="padding-top: 12px; padding-bottom: 12px"
              @click="submitRegistration"
            >
              Register
            </button>
            <button
              v-else
              class="btn btn-red"
              @click="submitRegistration"
              disabled
            >
              <span class="spinner-border spinner-border-sm"></span>
            </button>

            <!-- <div class="wrap"><p class="centre-line"><span>Or</span></p></div> -->

            <!-- <div class="row">
                            <div class="col-md-12">
                                <button class="btn btn-facebook" :onclick="authViaFacebook()">Facebook</button>
                            </div>
                            <div class="col-md-12 mt-3">
                                <button class="btn btn-google">Google</button>
                            </div>
                        </div> -->

            <div class="text-center mt-4 ff-desc">
              <p class="statements">
                By signing up, you agree to Selcare's
                <router-link to="/info/privacy-notice"
                  >Privacy Notice</router-link
                >.
              </p>
              <div class="redirectto">
                <span>
                  Have an account?
                  <router-link to="/auth/login">Login</router-link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "./../../utils/API";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      username: null,
      email: null,
      nric: null,
      password: null,
      confirm_password: null,
      errorPass: "",
      errorNRIC: "",
      isErrorRequired: false,
    };
  },
  computed: {
    ...mapGetters(["authProcessing", "authError"]),
  },
  watch: {
    nric() {
      this.nric = this.nric.replace(/[^0-9]/g, "");
    },
    username(){
      this.isErrorRequired = false
    },
    email(){
      this.isErrorRequired = false
    },
    nric(){
      this.isErrorRequired = false
    },
    password(){
      this.isErrorRequired = false
    },
    confirm_password(){
      this.isErrorRequired = false
    }
  },
  methods: {
    ...mapActions(["signup", "clearAllErrors"]),
    isNumberic(str) {
      return /^-?\d+$/.test(str);
    },
    submitRegistration() {
      if (!this.username || !this.email || !this.nric || !this.password) {
        return this.isErrorRequired = true;
      }

      let redirectto = this.$route.query.redirect
        ? this.$route.query.redirect
        : null;

      if (!this.isNumberic(this.nric)) {
        this.errorNRIC = "please insert a valid NRIC";
      } else {
        this.errorNRIC = "";
      }

      if (this.password != this.confirm_password) {
        this.errorPass = "please sure your passwords match.";
      } else {
        this.errorPass = "";
      }

      if (this.password == this.confirm_password) {
        this.signup({
          username: this.email.substring(0, this.email.indexOf('@')),
          email: this.email,
          nric: this.nric,
          password: this.password,
          firstname: this.username,
          redirectto: redirectto,
        });
      }
    },
    authViaFacebook() {
      let url = `${API.getAPIUrl()}/connect/facebook`;
      return `location.href='${API.getAPIUrl()}/connect/facebook'`;
    },
  },
  mounted() {},
  beforeDestroy() {
    this.clearAllErrors()
  },
};
</script>

<style scoped>
.main-body {
  padding: 60px 0px;
  /*font-family: "Karla",serif;*/
}

.img {
  padding: 130px 0px;
}

.card {
  border-radius: 0px;
  border: none;
}
.card .card-body {
  margin: 10px 0px;
}
.card .card-title {
  margin-bottom: 30px;
}
.card .card-body .form-group {
  margin-bottom: 25px;
}
.card .card-body input {
  border-radius: 0px;
}
.card .card-body button {
  width: 100%;
  border-radius: 0px;
  border: none;
}
.card .card-body .btn-red {
  background-color: #a91a18;
  color: #fff;
}
.card .card-body .btn-facebook {
  background-color: #1877f2;
  color: #fff;
  font-weight: bold;
}
.card .card-body .btn-google {
  background-color: #4285f4;
  color: #fff;
  font-weight: bold;
}
.card .card-body p {
  font-size: 14px;
}
.card .card-body span {
  font-size: 14px;
}
.card .card-body a {
  color: #a91a18;
}

/** Centre line */
.wrap {
  margin: 10px 0px;
  position: relative;
  height: 50px;
}

p.centre-line {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
p.centre-line:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1.5px;
  top: 50%;
  left: 0;
  z-index: -1;
  background: #dbdbdb;
}
p.centre-line span {
  background-color: #ffffff;
  color: #dbdbdb;
  padding: 1rem;
  display: inline-block;
  text-transform: uppercase;
}
</style>