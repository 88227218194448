<template>
  <div>
    <!-- Header -->
    <!-- <div id="auth-navbar" class="header">
      <div class="container">
        <nav class="navbar navbar-expand navbar-light">
          <div
            class="
              navbar-left navbar-collapse
              collapse
              w-100
              order-1 order-md-0
              dual-collapse2
            "
          >
            <router-link to="/" class="navbar-brand mr-auto">
              <img
                src="/img/logo_selcare_512.png"
                height="45"
                alt=""
                loading="lazy"
              />
            </router-link>
          </div>
        </nav>
      </div>
    </div> -->

    <!-- Body -->
    <div
      class="body gray-color d-flex justify-content-center align-items-center"
    >
      <div class="container">
        <div id="logo-mobile-only" class="d-none">
          <router-link to="/" class="">
            <img
              src="/img/logo_selcare_512.png"
              height="55"
              alt=""
              loading="lazy"
            />
          </router-link>
        </div>
        <login-comp v-if="$route.path == '/auth/login'" />
        <signup-comp v-if="$route.path == '/auth/signup'" />
        <reset-password-comp v-if="$route.path == '/auth/resetpassword'" />
      </div>
    </div>
    <!-- <div class="body white-color">
            <div class="container">
                any component that need white background
            </div>
        </div> -->

    <!-- Footer -->
    <!-- No footer for reset password components-->
    <footer-comp
      :topfooter="false"
      v-if="$route.path != '/auth/resetpassword'"
    />
  </div>
</template>

<script>
import LoginComp from "../../components/customer-auth/Login";
import SignupComp from "../../components/customer-auth/Signup";
import ResetPasswordComp from "../../components/customer-auth/ResetPassword";
import FooterComp from "../../containers/Footer";

export default {
  components: {
    LoginComp,
    SignupComp,
    ResetPasswordComp,
    FooterComp,
  },
};
</script>

<style scoped>
.header {
  padding: 10px 0px;
  /*font-family: "Karla",serif;*/
}
.header span {
  color: #a91a18;
  font-size: 18px;
  text-transform: initial;
  /* font-weight: bold; */
}
.body.gray-color {
  height: calc(100vh - 92px) !important;
  background-image: url(../../assets/Vector.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f6f6f6;
}

@media (max-width: 767px) {
  .body.gray-color {
    height: initial !important;
  }
  .main-body {
    padding: 20px 0px;
  }

  #logo-mobile-only {
    display: flex!important;
    justify-content: center;
    padding-top: 40px;
  }
}

.body.white-color {
  background-color: #ffffff;
}
</style>
